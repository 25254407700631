.financial-year-selector {
  @media only screen and (max-width: 500px) {
    font-size: 11px;
  }
  .dropdown-menu-button {
    margin-left: 5px;
  }
  .warning-info {
    color: red;
  }
}

@primary-color: #3F7FCA;@disabled-color: #515458;@disabled-bg: #f0f0f0;