@import '../../client/styles/common';

.maintenance {
  text-align: center;
  padding-top: 50px;
  .logo {
    padding: 16px 0px;
  }
}

@primary-color: #3F7FCA;@disabled-color: #515458;@disabled-bg: #f0f0f0;