@import '../../client/styles/variables.less';

.main-container {
  background: @background-color;
  min-height: 100%;
  .main-sider {
    background: @menu-background-color;
    overflow: auto;
    top: 64px;
    left: 0;
    height: calc(100vh - 64px);
    position: fixed;
    max-width: @main-sider-width !important;
    border-right: 1px solid @gray-50-border;
  }

  .toggle-collapse-ele-cnt {
    margin-top: 64px;
    margin-left: 40px;
    z-index: 999999;
    cursor: pointer;
    .toggle-collapse-ele {
      background: @blue-card-border;
      width: 20px;
      height: 20px;
      line-height: 18px;
      font-size: 9px;
      position: fixed;
    }
  }

  .toggle-element-expanded-margin {
    margin-left: @toggle-icon-left-visible;
  }
  .toggle-element-collapsed-margin {
    margin-left: @toggle-icon-left-hidden;
  }

  .main-sider-expanded-width {
    width: @main-sider-width !important;
    min-width: @main-sider-width !important;
    max-width: @main-sider-width !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 200;
  }
  .main-sider-width-hidden {
    width: @main-sider-width-hidden !important;
    min-width: @main-sider-width-hidden !important;
    max-width: @main-sider-width-hidden !important;
  }

  .main-menu {
    background: @menu-background-color !important;
  }

  .app-header {
    background: @background-color;
    position: fixed;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    z-index: 999;
    padding-left: 16px;
    padding-right: 16px;
    border-bottom: 2px solid @gray-50-border;

    .header-menu {
      display: none;
      margin-top: 8px;
      svg {
        margin-bottom: 9px;
      }
    }

    .action-container {
      display: flex;
      width: 100%;
      .ant-col {
        flex: 1 1 auto;
      }
      .user-avtar-cnt {
        margin-left: 5px;
        p {
          margin-bottom: 3px;
        }
        .user-name {
          font-size: 14px;
          @media only screen and (max-width: 500px) {
            display: none;
          }
        }
        .user-type {
          color: rgba(150, 150, 150, 1);
          font-size: 10px;
          margin-top: -4px;
          @media only screen and (max-width: 500px) {
            margin-top: 8px;
          }
        }
      }
      .message-notification {
        color: white;
        position: absolute;
        background-color: red;
        top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid;
        border-radius: 50px 50px 50px 50px;
        padding: 0 7px 0 7px;
        height: 17px;
        z-index: 1000;
      }
      .headerInfoAlignment {
        text-align: right;
        .notif-container {
          cursor: pointer;
          display: inline-block;
          margin-left: 5px;
        }
        @media only screen and (max-width: 500px) {
          text-align: left;
        }
      }
      .mail {
        object-fit: contain;
      }
    }
    .ant-dropdown-trigger {
      border: 0;
      color: #000;
    }
    .ant-avatar {
      cursor: pointer;
      color: @background-color;
      background: rgba(13, 100, 165, 1);
    }
  }

  .content-layout {
    background: @background-color;
    min-height: 100vh;
    min-width: 350px;
  }
  .main-body {
    margin: 16px 16px;
    background: @background-color;
    margin-top: 80px;
    margin-bottom: 8px;
    padding-left: 8px;
    flex: 1 0 auto;
  }

  .ant-back-top {
    right: 24px;
    bottom: 24px;
  }

  .ant-page-header-title-view-title {
    font-size: 24px !important;
  }

  @media only screen and (max-width: 600px) {
    .main-sider,
    .toggle-collapse-ele-cnt {
      display: none;
    }
    .main-body {
      margin-left: 16px !important;
    }
    .app-header {
      .logo {
        display: none;
      }
      .header-menu {
        display: inherit;
      }
    }
  }
}

.ant-message {
  .ant-message-notice {
    .ant-message-notice-content {
      background: #fff1f0;
    }
  }
  .anticon {
    top: -2px;
  }
}

.ant-divider-horizontal {
  margin: 0 0 !important;
}

.ant-calendar-picker {
  width: 100% !important;
}

.ant-spin-nested-loading {
  height: 100%;
  .ant-spin {
    max-height: 100% !important;
  }
}

.ant-menu-submenu {
  .ant-menu-item {
    a {
      color: rgba(0, 0, 0, 0.65);
    }
  }
}

.idle-warning {
  svg {
    width: 70px;
    height: 70px;
    padding-bottom: 10px;
  }
}
.idle-modal {
  width: fit-content;
  z-index: 9999999;
}
.idle-modal-body {
  font-size: 18px;
}

.footer {
  background: @background-color;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 2px solid @gray-50-border;
  flex-shrink: 0;
  a {
    color: @gray-80;
    &:hover {
      color: @blue-hyperlink;
    }
  }
  font-size: 12px;
  .ant-col {
    padding: 0px !important;
  }
}

.footer-divider {
  color: @black;
  opacity: 1;
}

.like-anchor {
  align-items: normal;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgb(0, 0, 238);
  border-style: none;
  box-sizing: content-box;
  color: rgb(0, 0, 238);
  cursor: pointer;
  display: inline;
  font: inherit;
  height: auto;
  padding: 2;
  perspective-origin: 0 0;
  text-align: start;
  text-decoration: underline;
  transform-origin: 0 0;
  width: auto;
  outline: 1 solid red;
  -moz-appearance: none;
  -webkit-logical-height: 1em;
  -webkit-logical-width: auto;
}

@supports (-moz-appearance: none) {
  .like-anchor::-moz-focus-inner {
    border: none;
    padding: 0;
  }

  .like-anchor:focus {
    outline-style: dotted;
    outline-width: 1px;
  }
}

@primary-color: #3F7FCA;@disabled-color: #515458;@disabled-bg: #f0f0f0;