@import './variables';

//Common classes

.width100 {
  width: 100% !important;
}
.width25 {
  width: 25% !important;
}
.width30 {
  width: 30% !important;
}

.bold {
  font-weight: bold !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.italic {
  font-style: italic;
}
.uppercase {
  text-transform: uppercase !important;
}

.flex {
  display: flex !important;
}

.block {
  display: block !important;
}

.inline {
  display: inline-block !important;
}

.flex-baseline {
  display: flex !important;
  align-items: baseline !important;
}
.flex-center {
  display: flex !important;
  align-items: center !important;
}
.flex-space-between {
  display: flex !important;
  justify-content: space-between !important;
}
.flex-justify-center {
  display: flex !important;
  justify-content: center !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.opacity7 {
  opacity: 0.7;
}

.no-padding {
  padding: 0 !important;
}

//padding
.pt30 {
  padding-top: 30px !important;
}
.pt20 {
  padding-top: 20px !important;
}
.pt10 {
  padding-top: 10px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}
.pb20 {
  padding-bottom: 20px !important;
}
.pb30 {
  padding-bottom: 30px !important;
}

//margin
.ml5 {
  margin-left: 5px !important;
}
.ml10 {
  margin-left: 10px !important;
}
.ml15 {
  margin-left: 15px !important;
}
.ml20 {
  margin-left: 20px !important;
}
.mr5 {
  margin-right: 5px !important;
}
.mr10 {
  margin-right: 10px !important;
}
.mr15 {
  margin-right: 15px !important;
}
.mr20 {
  margin-right: 20px !important;
}
.mt5 {
  margin-top: 5px !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mt15 {
  margin-top: 15px !important;
}
.mt20 {
  margin-top: 20px !important;
}
.mb5 {
  margin-bottom: 5px !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb15 {
  margin-bottom: 15px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
// fonts

.font-23 {
  font-size: 23px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-11 {
  font-size: 11px !important;
}
.font-10 {
  font-size: 10px !important;
}

//blue border box
.blue-box {
  padding: 10px 20px;
  background: @blue-10 0% 0% no-repeat padding-box;
  border: 2px solid @blue-30;
}

//divider
.divider {
  margin: 5px 0 !important;
}

//
.show {
  display: block !important;
}

.hide {
  display: none !important;
}

.custom-bold {
  font-weight: bold;
  color: #231f20;
}
