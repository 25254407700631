@import './variables';

.primary-color {
  color: @primary-color;
}

body {
  margin: 0;
  padding: 0;
  line-height: 1.5 !important;
  font-family: @font-stack !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: @background-color !important;
}

#root {
  width: 100%;
  background: #fff;
  min-width: 350px;
  font-family: @font-stack !important;
}

.ant-input[disabled] {
  border: 1px solid @gray-50;
}

.ant-form-item-label {
  padding-bottom: 0px !important;
  > label {
    color: @gray-100 !important;
    height: 19px !important;
  }
}

.ant-form-item {
  margin-bottom: 12px !important;
}

.ant-btn-primary {
  color: @white-100 !important;
}

.ant-btn-success {
  background-color: @green-50 !important;
  border-color: @green-50 !important;
  color: @white-100 !important;
  &:hover,
  &:active {
    border-color: @green-50 !important;
  }
  &[disabled] {
    background-color: @white-75 !important;
    border-color: @gray-70 !important;
    &:hover,
    &:active {
      border-color: @gray-70 !important;
    }
  }
}

.ant-btn-primary[disabled] {
  color: #000 !important;
}

.item-required {
  .ant-form-item-label::before {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d !important;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}

.full-height {
  height: 100vh;
}

.ant-menu-inline-collapsed {
  width: @main-sider-width-hidden !important;
  .ant-menu-item {
    padding: 0px 14px !important;
  }
}

.ant-menu-inline {
  .ant-menu-item {
    padding: 0px 14px !important;
  }
}

.ant-menu-item {
  .anticon {
    img {
      margin-bottom: 7px;
    }
  }
  .anticon + span {
    color: #000;
  }
}

.ant-menu-item-selected {
  border-right: 3px solid rgba(13, 100, 165, 1) !important;
  background-color: #fff !important;
  .anticon + span {
    color: @primary-color !important;
  }
}

.ant-menu {
  &.ant-menu-dark {
    background: @menu-background-color !important;
  }
}

.border-less-btn {
  font-size: 14px;
  color: @blue-60 !important;
}

.ant-btn[disabled] {
  color: @gray-50 !important;
}

.ant-form-item-inline {
  display: flex !important;
  .ant-form-item-label {
    order: 2 !important;
    margin-left: 10px;
    margin-top: 4px;
    font-weight: bold;
    > label::after {
      content: ' - ';
      padding: 0 5px;
    }
  }
  .ant-form-item-control {
    flex-grow: 0 !important;
  }
}

.ant-form-item-inline-no-dash {
  display: flex !important;
  .ant-form-item-label {
    order: 2 !important;
    margin-left: 10px;
    margin-top: 2px;
    font-weight: bold;
    > label::after {
      content: '  ';
      padding: 0 5px;
    }
  }
  .ant-form-item-control {
    flex-grow: 0 !important;
  }
}
.ant-anchor-ink {
  &:before {
    background: none !important;
  }
}

.anticon-warning {
  color: @yellow-50 !important;
}

.error-border {
  border: 1px solid @red !important;
}
.error-message-cnt {
  color: @red;
}

.check-circle {
  color: @green-50 !important;
  opacity: 1 !important;
  padding-right: 5px !important;
  font-size: 17px !important;
  position: relative !important;
  bottom: 5px !important;
}
.info-circle {
  color: @blue-60;
  padding-right: 5px;
  opacity: 1;
  font-size: 17px;
  position: relative;
  bottom: 5px;
}
.warning {
  color: @exclamation-triangle-orange;
  padding-right: 5px;
  font-size: 17px;
  position: relative;
  bottom: 5px;
}
.no-header-notif {
  .ant-notification-notice-message {
    display: none;
  }
}

//white box with shadow

.white-box-shadow {
  background: @background-color 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000003b;
  opacity: 1;
  height: 70%;
  padding: 15px;
}

.float-left {
  float: left;
}

@primary-color: #3F7FCA;@disabled-color: #515458;@disabled-bg: #f0f0f0;