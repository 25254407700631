@import '../../client/styles/variables.less';
@import '../../client/styles/common.less';

.okta-widget {
  background-color: #f9f9f9;
  height: 100vh;
}

#okta-sign-in {
  &.auth-container {
    border: none;
    border-radius: 6px;
    h2 {
      font-size: 24px;
      color: @gray-100;
    }
    &.main-container {
      font-family: @font-stack;
      width: 500px;
      box-shadow: 0px 3px 22px #51545826;
      min-height: 500px;
    }
    .okta-sign-in-header {
      border: none;
      padding-bottom: 0px;
      padding-top: 10px;
    }
    .okta-form-label {
      color: @gray-100;
      font-weight: normal;
    }
    .button-primary {
      font-family: @font-stack !important;
      background: @green-50 !important;
      border-color: @green-50 !important;
      font-size: 16px;
    }
    .o-form-explain {
      display: none;
    }
  }
  &.no-beacon {
    .auth-content {
      padding-top: 10px;
    }
  }

  .auth-org-logo {
    max-width: none;
    max-height: none;
    width: 60%;
  }
  .focused-input,
  .link.help:focus {
    box-shadow: none;
  }

  margin-top: 0px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.auth-footer {
  display: none;
}

.primary-auth-container {
  float: left;
  text-align: center;
  .btn-customAuth {
    &:extend(.common-customize-okta-button);
    color: @gray-20 !important;
    margin: 0 !important;
    height: 30px !important;
    margin-bottom: 20px !important;
  }
  .auth-divider {
    display: none;
  }
  .button-as-text,
  .contact-text,
  .email-link {
    height: auto;
    display: inline !important;
    cursor: context-menu;
    &:extend(.common-customize-okta-button);
    font-size: 11px !important;
    color: @gray-20 !important;
  }
  .button-as-text {
    font-weight: bold !important;
  }
  .email-link {
    color: #00e !important;
    &:hover {
      cursor: pointer;
    }
  }
}

.outer-container {
  display: flex;
  #video_contents {
    display: none;
  }
}
.common-customize-okta-button {
  background: none !important;
  border: none !important;
  line-height: 20px !important;
}

@primary-color: #3F7FCA;@disabled-color: #515458;@disabled-bg: #f0f0f0;