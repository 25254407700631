@import '../../../client/styles/variables.less';
@import '../../../client/styles/common.less';

.cookie-details-section {
  border: 1px solid #dddddd;
  padding: 5px;
  margin-top: 20px;
}
.ant-notification-bottomLeft {
  margin-left: 0px;
}
.coookie-notice-btn {
  .ant-notification-notice-btn {
    float: none !important;
  }
  .button-section {
    margin-top: 10px;
  }
}
.privacy-details {
  padding: 5px;
  margin-top: 10px;

  table,
  th,
  td {
    border: 1px solid black;
  }
}
.boxborder {
  border: 2px solid black;
  margin: 10px;
}

@primary-color: #3F7FCA;@disabled-color: #515458;@disabled-bg: #f0f0f0;