.custom-table-cont {
  margin-top: 8px;
  .ant-table {
    overflow-x: auto;
  }
  .no-wrap {
    white-space: nowrap;
  }
}

@primary-color: #3F7FCA;@disabled-color: #515458;@disabled-bg: #f0f0f0;